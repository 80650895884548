export const userState = {
    token: null,
    basicDetails: {
        user: {},
        // subscription: {}
    },
    list: {
        results: 20,
        page: 1,
        data: []
    },
    recent: []
}

export const masterDataState = {
    home: [],
    movies: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    series: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    subs: [],
    referralUsers: {
        pageSize: 10,
        current: 1,
        data: [],
        total: 0
    },
    premieres: {
        movies: {
            pageSize: 10,
            current: 1,
            data: [],
            total: 0
        },
        series: {
            pageSize: 10,
            current: 1,
            data: [],
            total: 0
        }
    }
}