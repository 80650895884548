import {createStore} from "redux";
import {persistReducer, persistStore} from "redux-persist";
import rootReducer from "./reducers/rootReducer";
import storage from 'redux-persist/lib/storage';
import { masterDataState, userState } from "./initialState";
const persistConfig = {
    key: "root",
    storage
}
const configureStore = preloadedState => {
    const mainReducer = (state, action) => {
        if(action.type === "RESET_APP") {
            state = preloadedState
        }return rootReducer(state, action)
    }
    const persistedReducer = persistReducer(persistConfig, mainReducer);
    return createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) 
}
export default configureStore

export const store = configureStore({user: userState, masterData: masterDataState});
export const persistor = persistStore(store)