import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
    body: '#f6f7f8',
    text: '#000000',
    text_muted: '#404040',
    text_muted_dark: '#808080',
    shadow: "#d5d7e17d",
    // Colors
    color: '#ffffff',
    color_muted: '#efefef',
    color_muted_dark: '#dfdfdf'
};

export const darkTheme = {
    body: '#101010',
    text: '#ffffff',
    text_muted: '#bfbfbf',
    text_muted_dark: '#8f8f8f',
    shadow: "#3b3a2a",
    // Colors
    color: '#000000',
    color_muted: '#202020',
    color_muted_dark: '#404040'
};

export const GlobalStyles = createGlobalStyle`
    body {
        background: ${({ theme }) => theme.body} !important;
        color: ${({ theme }) => theme.text};
        transition: background 0.4s ease, color 0.4s ease;
    }
    .bg-body{
        background: ${({ theme }) => theme.body} !important;
        transition: background 0.4s ease;
    }
    input, input[type=text], input[type=file], input[type=password], input[type=email], input[type=tel], input[type=link], input[type=number], textarea, select,
    .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn, .rs-input {
        background: ${({ theme }) => theme.color};
        color: ${({ theme }) => theme.text};
        border: 2px solid ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease, color 0.4s ease, border 0.4s ease;
    }
    input:hover, input[type=file]:hover, input:hover, textarea:hover{
        background: ${({ theme }) => theme.color};
        border-color: ${({ theme }) => theme.color_muted} !important;
        transition: background 0.4s ease, border-color 0.4s ease;
    }
input:focus, input[type=text]:focus, input[type=number]:focus, input[type=email]:focus, input[type=tel]:focus, select:focus, input[type=link]:focus, textarea:focus,input[type=password]:focus,
    .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:focus {
        background: ${({ theme }) => theme.color_muted};
        border-color: ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease, border-color 0.4s ease;
    }
    .rs-input:focus{
        border-color: ${({ theme }) => theme.color_muted};
        transition: border-color 0.4s ease;
    }
    .rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover{
        border-color: ${({ theme }) => theme.color_muted};
        color: ${({ theme }) => theme.text};
        transition: color 0.4s ease, border-color 0.4s ease;
    }
    .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover{
        background: ${({ theme }) => theme.color_muted};
        color: ${({ theme }) => theme.text};
        transition: background 0.4s ease, color 0.4s ease;
    }
    h1, h2, h3, h4, h5, h6, p, .h1, .h2, .h3, .h4, .h5, .h6, .p {
        color: ${({ theme }) => theme.text};
        transition: color 0.4s ease;
    }
    .hover:hover{
        box-shadow: 0rem 0.5rem 2rem 0rem ${({ theme }) => theme.shadow} !important;
        transition: box-shadow 0.4s ease;
    }
    .bgtheme{
        background: ${({ theme }) => theme.color};
        background-color: ${({ theme }) => theme.color};
        transition: background 0.4s ease, background-color 0.4s ease;
    }
    .card, .rs-modal-content{
        background: ${({ theme }) => theme.color};
        border: 1px solid ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease, border 0.4s ease;
    }
    .border{
        border: 1px solid ${({ theme }) => theme.color_muted};
        transition: border 0.4s ease;
    }
    .overflowY-scroll::-webkit-scrollbar, .ub-ovflw-y_auto::-webkit-scrollbar{
        width: 5px;
        background: ${({ theme }) => theme.color_muted};
        border-radius: 5rem;
        transition: background 0.4s ease;
    }
    .overflowY-scroll::-webkit-scrollbar-thumb, .ub-ovflw-y_auto::-webkit-scrollbar-thumb{
        background: ${({ theme }) => theme.color_muted_dark};
        border-radius: 5rem;
        transition: background 0.4s ease;
    }
    button{
        background: ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease;
    }
    // Login
    .login .login-data {
        background: ${({ theme }) => theme.color};
        transition: background 0.4s ease;
    }
    .login .login-data .login-form footer{
        color: ${({ theme }) => theme.text_muted_dark};
        transition: color 0.4s ease;
    }
    // Header
    header.header{
        background: ${({ theme }) => theme.color};
        border-bottom: 1px solid ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease, border-bottom 0.4s ease;
    }
    header.header a{
        color: ${({ theme }) => theme.text};
        transition: color 0.4s ease;
    }
    header.header a:hover{
        background: ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease;
    }
    // Table
    table.episodeData, table.episodeData tbody tr td{
        border: 1px solid ${({ theme }) => theme.color_muted};
        transition: border 0.4s ease;
    }
    table.episodeData thead tr th{
        border: 1px solid ${({ theme }) => theme.color};
        transition: border 0.4s ease;
    }
    table.episodeData thead{
        background: ${({ theme }) => theme.color_muted};
        transition: background 0.4s ease;
    }
    // 
    .ub-bg-clr_white{
        background-color: ${({ theme }) => theme.body} !important;
        transition: background-color 0.4s ease;
    }
    .rs-btn-toggle, .rs-btn-toggle:hover{
        background-color: ${({ theme }) => theme.color_muted_dark};
        transition: background-color 0.4s ease;
    }
    div.movieThumbnailPreviewBlank,
    div.seriesThumbnailPreviewBlank,
    div.seasonThumbnailPreviewBlank{
        height: 240px;
        border: 2px solid ${({ theme }) => theme.color_muted_dark};
        border-style: dashed;
        transition: border 0.4s ease;
    }
    div.episodeThumbnailPreviewBlank{
        height: 10rem;
        border: 2px solid ${({ theme }) => theme.color_muted_dark};
        border-style: dashed;
        transition: border 0.4s ease;
    }
`;