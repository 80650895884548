import React from "react";
import { Progress } from 'rsuite';
import Logo from '../../assets/img/stay_connected_Logo.png'
import './loadingScreen.css'

const LoadingScreen = () => {
  const { Line } = Progress;
  return (
    <div className="loading">
      <img src={Logo} height="66" alt="Stay Connected TV" />
      <Line percent={60} status='active' showInfo={false} style={{width:"300px"}}/>
    </div>
  )
}
export default LoadingScreen
