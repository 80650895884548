import moment from "moment";

// export const baseUrl = 'https://stayconnectedtv.com';
export const baseUrl = 'https://dev.webologixglobal.com';

// login/signup urls
export const loginURL = baseUrl + '/api/v1/contentProvider/login/';
export const forgotPasswordURL = baseUrl + '/api/v1/contentProvider/forgotPassword';
export const refreshUrl = baseUrl + '/api/v1/refresh/';
export const basicDetailURL = baseUrl + '/api/v1/contentProvider/basicDetails';
export const dashboardURL = baseUrl + '/api/v1/contentProvider/dashboard';

export const userURL = baseUrl + '/api/v1/contentProvider/users';
// export const userStatUrl = baseUrl + '/api/v1/admin/userStat';

export const effectiveWalletURL = baseUrl + '/api/v1/contentProvider/effectiveWallet/';
export const withdrawAmountURL = baseUrl + '/api/v1/contentProvider/withdrawAmount/';
export const walletHistoryURL = baseUrl + '/api/v1/contentProvider/walletHistory/';
//masterData
export const movieURL = baseUrl + '/api/v1/masterData/movies/';
export const seriesURL = baseUrl + '/api/v1/masterData/series/';
export const genreUrl = baseUrl + '/api/v1/masterData/genre/';
export const languageUrl = baseUrl + '/api/v1/masterData/language/';
export const referralUrl = baseUrl + '/api/v1/masterData/referral/';
export const premierUrl = baseUrl + '/api/v1/masterData/premiers/';

export async function getValidToken(tokens) {
	let created_at;
	if (Object.keys(tokens).indexOf('recreated_at') !== -1) {
		created_at = tokens['recreated_at']
	} else {
		created_at = tokens['created_at']
	}
	if ((moment.utc(created_at, 'YYYY-MM-DDTHH.mm.ss').add(moment.duration(tokens['token_expiry'], 'seconds'))).local().isBefore(moment())) {
		if ((moment.utc(tokens['created_at'], 'YYYY-MM-DDTHH.mm.ss').add(tokens['refresh_expiry'], 'days')).local().isAfter(moment())) {
			let resp = await fetch(refreshUrl, {
				method: 'POST',
				headers:
				{
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					'refresh': tokens['refresh']
				})
			});
			if (resp.status === 200) {
				let data = await resp.json()
				let tok = JSON.parse(JSON.stringify(tokens))
				tok['token'] = data['access']
				tok['recreated_at'] = moment().utc().format('YYYY-MM-DD[T]HH.mm.ss')
				tok['refresh'] = data['refresh']
				return tok
			}
		} else {
			return null
		}
	}
	return tokens
}

export async function checkValidity(tokens) {
	let created_at;
	if (Object.keys(tokens).indexOf('recreated_at') !== -1) {
		created_at = tokens['recreated_at']
	} else {
		created_at = tokens['created_at']
	}
	if ((moment.utc(created_at, 'YYYY-MM-DDTHH.mm.ss').add(moment.duration(tokens['token_expiry'], 'seconds'))).local().isBefore(moment())) {
		if ((moment.utc(tokens['created_at'], 'YYYY-MM-DDTHH.mm.ss').add(tokens['refresh_expiry'], 'days')).local().isAfter(moment().add(1, 'days'))) {
			let resp = await fetch(refreshUrl, {
				method: 'POST',
				headers:
				{
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					'refresh': tokens['refresh']
				})
			});
			if (resp.status === 200) {
				let data = await resp.json()
				let tok = JSON.parse(JSON.stringify(tokens))
				tok['token'] = data['access']
				tok['recreated_at'] = moment().utc().format('YYYY-MM-DD[T]HH.mm.ss')
				tok['refresh'] = data['refresh']
				return tok
			} else {
				return false
			}
		} else {
			return false
		}
	} else {
		return tokens
	}
}
