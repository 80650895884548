import React, { lazy, useEffect, useState, Suspense } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import { GlobalStyles, lightTheme, darkTheme } from "./styles/globalStyles";
import { ThemeProvider } from "styled-components";
import LoadingScreen from "./components/auth/LoadingScreen";
import { checkValidity } from "./config/apis";
import { login } from "./store/reducers/userSlice";

const Header = lazy(() => import("./components/navigation/header"));
const Login = lazy(() => import("./components/auth/Login"));
const ForgotPassword = lazy(() => import("./components/auth/forgotPassword"));
const Dashboard = lazy(() => import("./components/dashboard/indexNew"));
const Revenue = lazy(() =>
    import("./components/revenueDetails/RevenueDetails")
);
const ReferralUsers = lazy(() =>
    import("./components/referralUsers/referralUsers")
);
const Premiere = lazy(() => import("./components/premiere/index"))
const MovieData = lazy(() => import("./components/libraries/movies/movieData"));
const SeriesData = lazy(() => import("./components/libraries/series/seriesData"));
const Libraries = lazy(() => import("./components/libraries/index"));
const Wallet = lazy(() => import("./components/wallet/wallet1"));

const useDarkMode = () => {
    const [theme, setTheme] = useState("light");
    const setMode = (mode) => {
        window.localStorage.setItem("theme", mode);
        setTheme(mode);
    };
    const toggleTheme = () => {
        theme === "dark" ? setMode("light") : setMode("dark");
    };
    useEffect(() => {
        const localTheme = window.localStorage.getItem("theme");
        localTheme ? setTheme(localTheme) : setMode("light");
    }, []);
    return [theme, toggleTheme];
};

function App(props) {
    const [theme, toggleTheme] = useDarkMode();
    const [validating, setValidating] = useState(true);
    useEffect(() => {
        if (props.token !== null) {
            checkValidity(props.token).then((status) => {
                if (status === false) {
                    props.reset();
                } else {
                    props.login(status);
                }
                setTimeout(() => {
                    setValidating(false);
                }, 10);
            });
        } else {
            setValidating(false);
        }
    }, []);
    const themeMode = theme === "light" ? lightTheme : darkTheme;
    if (validating) {
        return <LoadingScreen />;
    }
    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            <Suspense fallback={<LoadingScreen />}>
                <Router>
                    {props.token === null ? (
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={(props) => (
                                    <Login
                                        {...props}
                                        theme={theme}
                                        toggleTheme={toggleTheme}
                                        title="Login | Stay Connected TV"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/forgotPassword"
                                render={(props) => (
                                    <ForgotPassword
                                        {...props}
                                        theme={theme}
                                        toggleTheme={toggleTheme}
                                        title="Forgot Password | Stay Connected TV"
                                    />
                                )}
                            />
                            <Route
                                path="/"
                                render={() => {
                                    return <Redirect to="/" />;
                                }}
                            />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route
                                exact
                                path="/libraries/movieData"
                                render={(props) => (
                                    <MovieData
                                        {...props}
                                        component={MovieData}
                                        title="Movie | Stay Connected TV"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/libraries/seriesData"
                                render={(props) => (
                                    <SeriesData
                                        {...props}
                                        component={SeriesData}
                                        title="Series | Stay Connected TV"
                                    />
                                )}
                            />
                            <div>
                                <Header theme={theme} toggleTheme={toggleTheme} />
                                <Switch>
                                    <Route
                                        exact
                                        path="/dashboard"
                                        render={(props) => (
                                            <Dashboard
                                                {...props}
                                                theme={theme}
                                                toggleTheme={toggleTheme}
                                                title="Dashboard | Stay Connected TV"
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/revenue"
                                        render={(props) => (
                                            <Revenue
                                                {...props}
                                                theme={theme}
                                                toggleTheme={toggleTheme}
                                                title="Revenue | Stay Connected TV"
                                            />
                                        )}
                                    />
                                      <Route
                                        exact
                                        path="/premiere"
                                        render={(props) => (
                                            <Premiere
                                                {...props}
                                                theme={theme}
                                                toggleTheme={toggleTheme}
                                                title="Premiere | Stay Connected TV"
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/libraries"
                                        render={(props) => (
                                            <Libraries
                                                {...props}
                                                component={Libraries}
                                                title="Libraries | Stay Connected TV"
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/referral_users"
                                        render={(props) => (
                                            <ReferralUsers
                                                {...props}
                                                theme={theme}
                                                toggleTheme={toggleTheme}
                                                title="Referral Details | Stay Connected TV"
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/wallet"
                                        render={props => (
                                            <Wallet
                                                {...props}
                                                theme={theme}
                                                toggleTheme={toggleTheme}
                                                title="Wallet | Stay Connected TV"
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path="/premiere"
                                        render={(props) => (
                                            <Premiere
                                                {...props}
                                                theme={theme}
                                                toggleTheme={toggleTheme}
                                                title="Premiere | Stay Connected TV"
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        render={(props) => <Redirect {...props} to="/dashboard" />}
                                    />
                                </Switch>
                            </div>
                        </Switch>
                    )}
                </Router>
            </Suspense>
        </ThemeProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        token: state.user.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch({ type: "RESET_APP" }),
        login: (data) => dispatch(login(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
