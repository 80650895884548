import {createSlice} from '@reduxjs/toolkit'
import {masterDataState} from '../initialState';

const masterDataSlice = createSlice({
    name: "masterData",
    initialState: masterDataState,
    reducers: {
        initHome: (state, action) => {
            state.home = action.payload['home']
            state.movies = action.payload['movies']
            state.series = action.payload['series']
        },
        markAddedToList: (state, action) => {
            for (let i = 0; i < state.home.length; i++) {
                let index = state.home[i]['data'].findIndex(value => {
                    return value.id.toString() === action.payload[1].toString() && (action.payload[0] === 'movie' ? !Object.keys(value).includes('seasons') : Object.keys(value).includes('seasons'))
                })
                if (index !== -1) {
                    state.home[i]['data'][index]['inList'] = true
                }
            }
            if (action.payload[0] === 'movie') {
                for (let i = 0; i < state.movies.length; i++) {
                    let index = state.movies[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.movies[i]['data'][index]['inList'] = true
                    }
                }
            } else {
                for (let i = 0; i < state.series.length; i++) {
                    let index = state.series[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.series[i]['data'][index]['inList'] = true
                    }
                }
            }
        },
        markRemovedFromList: (state, action) => {
            for (let i = 0; i < state.home.length; i++) {
                let index = state.home[i]['data'].findIndex(value => {
                    return value.id.toString() === action.payload[1].toString() && (action.payload[0] === 'movie' ? !Object.keys(value).includes('seasons') : Object.keys(value).includes('seasons'))
                })
                if (index !== -1) {
                    state.home[i]['data'][index]['inList'] = false
                }
            }
            if (action.payload[0] === 'movie') {
                for (let i = 0; i < state.movies.length; i++) {
                    let index = state.movies[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.movies[i]['data'][index]['inList'] = false
                    }
                }
            } else {
                for (let i = 0; i < state.series.length; i++) {
                    let index = state.series[i]['data'].findIndex(value => {
                        return value.id.toString() === action.payload[1].toString()
                    })
                    if (index !== -1) {
                        state.series[i]['data'][index]['inList'] = false
                    }
                }
            }
        },
        setSubs: (state, action) => {
            state.subs = action.payload
        },
        setSubsData: (state, action) => {
            state.subs = action.payload
        },
        setMovieData: (state, action) => {
            state.movies.data = action.payload['results']
            state.movies.total = action.payload['count']
            state.movies.current = action.payload['current']
        },
        setSeriesData: (state, action) => {
            state.series.data = action.payload['results']
            state.series.total = action.payload['count']
            state.series.current = action.payload['current']
        },
        deleteMovie: (state, action) => {
            let index = state.movies.data.findIndex((obj) => obj['id'] === action.payload)
            state.movies.data.splice(index, 1)
            state.movies.total = state.movies.total - 1
        },
        deleteSeries: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload)
            state.series.data.splice(index, 1)
            state.series.total = state.movies.total - 1
        },
        deleteEpisodes: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload['series'])
            let index2 = state.series.data[index].seasons.findIndex((obj) => obj['id'] === action.payload['season'])
            let index3 = state.series.data[index].seasons[index2].episode.findIndex((obj) => obj['id'] === action.payload['id'])
            state.series.data[index].seasons[index2].episode.splice(index3, 1)
        },
        deleteSeasons: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload['series'])
            let index2 = state.series.data[index].seasons.findIndex((obj) => obj['id'] === action.payload['id'])
            state.series.data[index].seasons.splice(index2, 1)
        },
        setReferral: (state, action) => {
            state.referral = action.payload
        },
        setReferralUsers: (state, action) => {
            state.referralUsers.data = action.payload['results']
            state.referralUsers.total = action.payload['count']
            state.referralUsers.current = action.payload['current']
        },
        saveSeason: (state, action) => {
            let index = state.series.data.findIndex((obj) => obj['id'] === action.payload['series'])
            let index2 = state.series.data[index].seasons.findIndex((obj) => obj['id'] === action.payload['id'])
            if (index2 !== -1) {
                state.series.data[index].seasons.splice(index2, 1, action.payload)
            } else {
                state.series.data[index].seasons.push(action.payload)
            }
        },
        setPremieres: (state, action) => {
            if(action.payload['tab'] === 'movie') {
                state.premieres.movies.data = action.payload['results']
                state.premieres.movies.total = action.payload['count']
                state.premieres.movies.current = action.payload['current']
            }
            if(action.payload['tab'] === 'series') {
              state.premieres.series.data = action.payload['results']
              state.premieres.series.total = action.payload['count']
              state.premieres.series.current = action.payload['current']
            }
      }
    }
});
export const {initHome, markAddedToList, markRemovedFromList, setSubs,
    setSubsData, 
    setReferral, setReferralUsers, setMovieData, setSeriesData, deleteEpisodes, deleteMovie,
     deleteSeries, deleteSeasons, saveSeason, setPremieres} = masterDataSlice.actions
export default masterDataSlice.reducer